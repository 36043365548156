<template>
  <div>
    <div v-if="marchine " class="marchine-container">
      <div class="marchine-terminal_toolbar d-md-flex justify-space-between ">
        <div class="butt pt-2 pt-md-0">
          <button class="marchine-btn marchine-btn-color" @click="closeDialog"></button>
          <button class="marchine-btn"></button>
          <button class="marchine-btn"></button>
        </div>
        <p class="user mt-3 mt-md-0"><span class="">orangcode@admin:</span> ~</p>
        <div class="add_tab mt-4"><v-btn small  :loading="loading" @click="connectionToServer"  class="elevation-0  rounded-pill green white--text lighten-" >Establish an SSH connection <v-icon  class="ml-1" size="20px">mdi-power</v-icon></v-btn></div>
      </div>
      <div class="terminal_body mt-md-5 mt-12 pt-8 pt-md-0">
        <div class="terminal_promt">
          <span class="terminal_user d-none d-md-inline">orangcode@admin:</span>
          <span class="terminal_location">~</span>
          <span class="terminal_bling">$ {{ terminalText }} </span>
          <span class="terminal_cursor"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { methods } from "@/utils/constant.js";

export default {
    props:{
        marchine:{
            required:true,
            type:Object
        },
        callback:{
            required:true,
            type:Function
        },

    },
    computed:{
        terminalText(){
            if(this.loading == true) {
                return "connecting ..."
            }
            if(this.success == true){
                return "SSH connection established."
            }
            if(this.success == false){
                return "SSH connection failed."
            }

            return ` ssh -p ${this.marchine.port} ${this.marchine.username}@${this.marchine.host} `
        }
    },
    methods:{
        async connectionToServer(){
            try {
                this.loading = true

                await this.callback({
                    id:this.marchine._id
                })

                this.success=true
                await methods.sleep(3000)
                methods.dispatchSuccess(this.$store,"SSH connection established");

                this.$emit("success")
            } catch (error) {
              methods.dispatchError(this.$store,"SSH connection failed.");

                console.log(error)
                this.success=false
            }
            finally{
                this.loading = false
                await methods.sleep(3000)
                this.initialize()

            }
        },
        closeDialog(){
            this.$emit("closeDialog")
        },
        initialize(){
            this.success = null
            this.loading = false
        }
    },
    data(){
        return {
            loading:false,
            success:null,
        }
    }
};
</script>

<style>

.marchine-container {
  width: 100%;
  /* height: 100%; */
  min-height:20vh;
  /* background: #212121; */
  background: lightgrey;


}

.marchine-terminal_toolbar {
  /* display: flex; */
  height: 30px;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* background: #212121; */
  /* justify-content: space-between; */
}

.butt {
  display: flex;
  align-items: center;
}

.marchine-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 5px;
  font-size: 8px;
  height: 12px;
  width: 12px;
  box-sizing: border-box;
  border: none;
  border-radius: 100%;
  /* background: linear-gradient(#7d7871 0%, #595953 100%); */
  background: linear-gradient(#b6b2ae 0%, #d3d3cd 100%);
  /* text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2); */
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 1px 0px #41403A, 0px 1px 1px 0px #474642;
}

.marchine-btn-color {
  background: #ee411a;
}

.marchine-btn:hover {
  cursor: pointer;
}

.marchine-btn:focus {
  outline: none;
}

.butt--exit {
  background: linear-gradient(#f37458 0%, #de4c12 100%);
}

.add_tab {
  /* border: 1px solid #000;
  color: #000;
  padding: 0 6px;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  cursor: pointer; */
}

.user {
  /* color: #d5d0ce; */
  color: #000;
  margin-left: 6px;
  font-size: 14px;
  line-height: 15px;
}

.terminal_body {
  /* background: rgba(0, 0, 0, 0.6); */
  background: lightgray;
  height: calc(100% - 30px);
  padding-top: 2px;
  margin-top: -1px;
  font-size: 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.terminal_promt {
  display: flex;
}

.terminal_promt span {
  margin-left: 4px;
}

.terminal_user {
  /* color: #1eff8e; */
  color: #1c1b75;
}

.terminal_location {
  color: #4878c0;
}

.terminal_bling {
  /* color: #dddddd; */
  color: #141414;
}

.terminal_cursor {
  display: block;
  height: 14px;
  width: 5px;
  margin-left: 10px;
  animation: curbl 1200ms linear infinite;
}

@keyframes curbl {

  0% {
    background: #000;
  }

  49% {
    background: #000;
  }

  60% {
    background: transparent;
  }

  99% {
    background: transparent;
  }

  100% {
    background: #000;
  }
}
</style>