<template>
  <div>
    <div
      @click="toggleWorkspaceOpen()"
      class="short-workspace-btn rounded d-flex justify-space-between"
    >

      <p>
        <span
          class="short-workspace-icon primary rounded white--text py-2 px-2 mr-2"
          >{{ workspace.name[0] }}</span
        >
        <span class="short-icon-title">{{ workspace.name }}</span>
        <span class="icon-title ml-2"  v-if="workspace.gitLabId"><v-icon>mdi-gitlab</v-icon></span>
        <span class="icon-title ml-2"  v-if="!workspace.haveValidSubscription"><v-icon>mdi-lock</v-icon></span>

      </p>
      <div class="d-flex">
        <v-btn v-if="workspaceIsOpen" icon
          ><v-icon>mdi-chevron-up</v-icon></v-btn
        >
        <v-btn v-else icon><v-icon>mdi-chevron-down</v-icon></v-btn>
      </div>
    </div>

    <div v-if="workspaceIsOpen" class="pl-12 mt-2">
      <div @click="actionInWorkspace('show-projects')" class="d-flex  cursor-pointer hover-grey py-2 px-3 rounded justify-space-between ">
        <div>
          <v-icon size="16px" class="mr-3">mdi-code-tags</v-icon>
          <span class="short-worskpace-icon">Projects</span>
        </div>
      </div>
      <div @click="actionInWorkspace('show-marchine-servers')" class="d-flex  cursor-pointer hover-grey py-2 px-3 rounded justify-space-between ">
        <div>
          <v-icon size="16px" class="mr-3">mdi-server</v-icon>
          <span class="short-worskpace-icon">Servers</span>
        </div>
      </div>
      <div @click="actionInWorkspace('show-members')" class="d-flex  cursor-pointer hover-grey py-2 px-3 rounded justify-space-between ">
        <div>
          <v-icon size="16px" class="mr-3">mdi-account-multiple</v-icon>
          <span class="short-worskpace-icon">Members</span>
        </div>
      </div>
      <div @click="editWorkspace" class="d-flex hover-grey py-2 px-3 rounded cursor-pointer  justify-space-between ">
        <div>
          <v-icon size="16px" class="mr-3">mdi-pencil</v-icon>
          <span class="short-worskpace-icon">Edite Workspace</span>
        </div>
      </div>
      <div @click="deleteWorkspace" class="d-flex hover-grey py-2 px-3 rounded cursor-pointer  justify-space-between ">
        <div>
          <v-icon size="16px" class="mr-3">mdi-delete</v-icon>
          <span class="short-worskpace-icon">Delete Workspace</span>
        </div>
      </div>
    </div>


    
    <v-dialog v-model="showWorkspace" max-width="50rem">
      <editor-x
        @success="onSuccess"
        :fields="workspaceField"
        :callback="callBackWorkspace"
        :model="workspaceModel"
        title="Workspace"
        :withoutImages="false"
        v-if="showWorkspace"
        :submitText="workspaceModel && workspaceModel._id ? 'Update workspace' : 'Create workspace'"
      />
    </v-dialog>
  </div>
</template>


<script>
import { rules,methods } from "@/utils/constant.js";
import { updateWorkspace,deleteWorkspace } from "@/api/user.js";
import EditorX from '../universal/EditorX.vue';

export default {
  components: { EditorX },
  props:{
    workspace:{
      type:Object,
      required:true
    },
  },
  data() {
    return {
      workspaceIsOpen: false,
      showWorkspace:false,
      workspaceModel:{}
    };
  },
  computed:{
    workspaceField() {
      return [
        {
          name: "name",
          label: "Workspace Name",
          placeholder: "My workspace",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
        {
          name: "description",
          label: "Description",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules:[rules.max(300,'Your description must not exceed 300 characters')]
        },
      
       

        // {
        //   name: "sep-backend",
        //   label: "Project Additional Info",
        //   type: "Separator",
        //   size: "12px",
        //   weight: "600",
        //   width: "180px",
        //   align: "left",
        //   textType: "left",
        //   col: 12,
        //   md: 12,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        // },

        // {
        //   name: "image",
        //   label: "Project Image",
        //   type: "File",
        //   col: 12,
        //   md: 12,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   icon: "mdi-upload",
        // },
       
      ];
    },
  },
  methods: {
    toggleWorkspaceOpen() {
      this.workspaceIsOpen = !this.workspaceIsOpen;
    },
    editWorkspace(){
      this.showWorkspace = !this.showWorkspace;
      this.callBackWorkspace = updateWorkspace
      this.workspaceModel = Object.assign({},this.workspace)
    },
    deleteWorkspace() {
      methods.dispatchDeleteDialog(this.$store, {
        dialog: true,
        what: "Do you really want to delete this workspace",
        callback: deleteWorkspace,
        data: { id: this.workspace._id },
        successHandler: () => {
          this.onSuccess();
        },
        description: "All associated projects will be deleted, and you cannot undo this action.",
      });
    },
    initialize(){
      // this.workspaceIsOpen = false
      this.showWorkspace = false

      this.workspaceModel = {}
    },
    onSuccess() {
      this.$emit("refresh");
      this.initialize()
    },
    actionInWorkspace(action) {
      this.$emit("actionInWorkspace",{
        workspace:this.workspace,
        action:action,
      });
      
    },

    
  },
};
</script>

<style>
.short-workspace-btn {
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 0.5rem;
  transition: all 100ms ease-in-out;
}
.short-workspace-btn:hover {
  background: rgba(216, 216, 216, 0.407);
}

.short-worskpace-icon {
    font-size: 14px;
}
</style>