/* eslint-disable */

<template>
  <div class="">
    <v-container class="mx-0 px-0 px-md-2" fluid>
      <!-- side first -->
      <v-row>
        <v-col cols="12" md="3">
          <div id="home-sidebar" class="rounded-lg py-5 px-12 d-none d-md-block">
            <div>


              <button v-for="(btn, i) of sidebtns" :key="i" role="button" class="side-button mb-5"
                :class="activeClass == btn.name ? 'active' : ''" @click="chooseBtn(btn.name)">
                <v-icon class="mr-2">{{ btn.icon }}</v-icon>
                <v-badge :content="btn.badge" :value="btn.badge" color="red" overlap>
                  <span class="px-2 px-md-3">
                    {{ btn.name }}
                  </span>
                </v-badge>

              </button>
            </div>

            <!-- {{ workspaces }} -->
            <div class="mt-5">

              <div class="d-flex justify-space-between align-center mb-5">
                <h3 class="h4-title secondary--text">
                  <span>Your workspaces</span>
                  <span class="ml-2 rounded green white--text px-2 py-1">{{ workspaces.length }}/{{
                    activesSubscription ? activesSubscription.maxWorkspace : 1 }}</span>
                </h3>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="addWorspace" :disabled="disabledAddWorkspaceBtn(workspaces)"
                      icon><v-icon>mdi-plus</v-icon></v-btn>
                  </template>
                  <span>{{ getAddWorkspaceBtnTooltipeMessage(workspaces) }}</span>
                </v-tooltip>
              </div>

              <div>
                <div v-for="(workspace, i) of workspaces" :key="i">

                  <short-workspace :workspace="workspace" @refresh="onSuccess" @actionInWorkspace="selectWorkspace" />
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <div id="home-main-bar" class="rounded-lg   py-5 px-0 px-md-12 scrollbar-style-14 ">
            <div v-if="activeClass == 'Workspaces'">
              <div>
                <div class="d-flex justify-space-betwee align-center">
                  <h3 class="h3-title secondary--text">
                    <span>YOUR WORKSPACES</span>
                    <span class="d-md-none ml-2 rounded green white--text px-2 py-1">{{ workspaces.length }}/{{
                      activesSubscription ? activesSubscription.maxWorkspace : 1 }}</span>
                  </h3>
                  <v-tooltip class="d-md-non" bottom>

                    <template class="d-md-non" v-slot:activator="{ on, attrs }">
                      <v-btn small class="d-md-non ml-3 green white--text elevation-0 rounded"  v-bind="attrs" v-on="on" @click="addWorspace"
                        :disabled="disabledAddWorkspaceBtn(workspaces)" >
                        <span>Create </span>
                        <v-icon size=".9rem" class="ml-1">mdi-plus</v-icon>
                        <!-- <span class="ml-2 rounded green white--text px-2 py-1">{{ workspaces.length }}/{{
                    activesSubscription ? activesSubscription.maxWorkspace : 1 }}</span> -->
                      </v-btn>
                    </template>
                    <span>{{ getAddWorkspaceBtnTooltipeMessage(workspaces) }}</span>
                  </v-tooltip>
                </div>
                <div v-for="(workspace, i) of workspaces" :key="i">
                  <workspace @refresh="onSuccess" :workspace="workspace" />
                </div>
                <v-col v-if="!workspaces || workspaces.length == 0" cols="12">
                  <p class="text-center mb-12 h5-description">
                    You don't have workspace
                  </p>
                </v-col>
              </div>
              <div>
                <h3 class="h3-title secondary--text">
                  <span>GUEST WORKSPACES</span>
                </h3>
                <div v-for="(workspace, i) of guestWorkspaces" :key="i">
                  <workspace @refresh="onSuccess" :workspace="workspace" />
                </div>
                <v-col v-if="!guestWorkspaces || guestWorkspaces.length == 0" cols="12">
                  <p class="text-center mb-12 h5-description">
                    You don't have a guest workspace
                  </p>
                </v-col>
              </div>
            </div>
            <div v-if="activeClass == 'Templates'">
              <div>
                <h3 class="h3-title secondary--text">
                  <span>Templates</span>
                </h3>

               
                <p class="text-cente mt-3 h5-description">

                  This list is empty
                </p>
              </div>
            </div>
            <div v-if="activeClass == 'SelectedWorkspace'">
              <div>
                <h3 class="h3-title secondary--text">
                  <span>YOUR WORKSPACES</span>
                </h3>
                <workspace @refresh="onSuccess" :workspace="selectedWorkspace" :showProjects="showSelectedProjects"
                  :showMembers="showSelectedMembers" :showMarchineServers="showSelectedMarchineServers" />
              </div>
            </div>
            <div v-if="activeClass == 'Invitations to Workspace'">
              <h3 class="h3-title secondary--text mb-5">
                <span>INVITATION TO WORKSPACE</span>
              </h3>
              <v-simple-table dense>

                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Actions</th>
                      <th class="text-left">Workspace</th>
                      <th class="text-left">Role</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Expiration</th>
                      <th class="text-left">Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(invitation, i) of workspaceInvitations" :key="i">
                   

                      <td>
                        <v-menu v-if="['Waiting'].includes(invitation.status)" elevation="0" right offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" class="mr-10">
                              <v-icon color="primary" size="23">mdi-forwardburger</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-tooltip v-if="invitation.status == 'Waiting'" bottom color="green"  >

                              <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="joinWorkspaceLoading" class="" icon >
                               
                                  <v-icon size="20" @click="joinWorkspace(invitation)" v-bind="attrs" v-on="on"
                                    color="green">mdi-login</v-icon>
                                </v-btn>
                              </template>
                              <span>Join Workspace</span>
                            </v-tooltip>
                            <v-tooltip v-if="invitation.status == 'Waiting'" bottom color="red">

                              <template v-slot:activator="{ on, attrs }">
                                <v-btn :loading="rejectInvitationLoading" class="" icon>
                                  <v-icon size="20" @click="rejectInvitation(invitation)" v-bind="attrs" v-on="on"
                                    color="red">mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span>Reject Invitation</span>
                            </v-tooltip>
                          </v-list>
                        </v-menu>
                      </td>
                      <td>
                        <div class="d-flex pt-3">
                          <p>
                            <span class="short-workspace-icon primary rounded white--text py-2 px-2 mr-2">{{
                              invitation.workspace.name[0] }}</span>
                            <span class="short-icon-title">{{
                              invitation.workspace.name
                            }}</span>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span class="px-2 rounded py-2 h5-description" :class="roleClass(invitation.role)">{{
                            invitation.role }}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span class="px-2 rounded py-2 h5-description" :class="statusClass(invitation.status)">{{
                            invitation.status }}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span class="px-2 rounded py-2 h5-description">{{
                            invitation.accessExpirationDate
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="py-1">
                          <ul>
                            <li v-if="invitation.createdAt" class="h5-description mb-1">
                              <span class="h5-title">Invitation sented : </span>
                              {{ invitation.createdAt }}
                            </li>
                            <li v-if="invitation.acceptedAt" class="h5-description mb-1">
                              <span class="h5-title">Access granted :</span>
                              {{ invitation.acceptedAt }}
                            </li>
                            <li v-if="invitation.rejectedAt" class="h5-description mb-1">
                              <span class="h5-title">Access rejected :</span>
                              {{ invitation.rejectedAt }}
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="showWorkspace" max-width="50rem">
        <editor-x @success="onSuccess" :fields="workspaceField" :callback="callBackWorkspace" :model="workspaceModel"
          title="Workspace" :withoutImages="false" v-if="showWorkspace" :submitText="workspaceModel && workspaceModel._id
            ? 'Update workspace'
            : 'Create workspace'
            " />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ShortWorkspace from "@/components/user/ShortWorkspace.vue";
import Workspace from "@/components/user/Workspace.vue";

import { methods, rules } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";
import EditorX from "../../components/universal/EditorX.vue";
import {
  createWorkspace,
  rejectInvitation,
  acceptToJoinWorkspace,
} from "@/api/user.js";

export default {
  components: { Workspace, ShortWorkspace, EditorX },
  data() {
    return {
      // sidebtns: this.getSideBtns(),
      activeClass: "Workspaces",
      showWorkspace: false,
      workspaceModel: {},
      callBackWorkspace: null,
      workspaceInvitationLoading: false,
      rejectInvitationLoading: false,
      joinWorkspaceLoading: false,
      selectedWorkspace: null,
      showSelectedMembers: false,
      showSelectedProjects: true,
      showSelectedMarchineServers: false,
      toolTips :{}
    };
  },
  watch: {
  
    activeClass() {
      this.getGuestWorkspaces();
      this.getWorkspaceInvitations({});
    },
    workspaces() {
      for (let workspace of this.workspaces) {
        this.$socket.emit('joinWorkspace', workspace._id)
      }
    },
    guestWorkspaces() {
      for (let workspace of this.guestWorkspaces) {
        this.$socket.emit('joinWorkspace', workspace._id)
      }
    },
  
  },
  beforeRouteLeave(to,from,next) {
    if(to && ["AuthApp","Login","SignUp","ForgetPassword","VerifyRestaurationCode","VerifyEmail","NewPassword"] .includes(to.name)){
      for (let workspace of this.workspaces) {
      this.$socket.emit('leaveWorkspace', workspace._id)
    }
    for (let workspace of this.guestWorkspaces) {
      this.$socket.emit('leaveWorkspace', workspace._id)
    }
    }
    

    next();
  },
  computed: {
    ...mapGetters([
      "userProfile",
      "projects",
      "workspaces",
      "guestWorkspaces",
      "workspaceInvitations",
      "activesSubscription",
    ]),
    workspaceInvitationQuery(){
      return this.$route.query["workspace-invitation"]
    },
    sidebtns() {
      return [
        {
          icon: "mdi-code-tags",
          name: "Workspaces",
          badge: 0,
        },
        {
          icon: "mdi-code-braces",
          name: "Templates",
          badge: 0,
        },
        {
          icon: "mdi-newspaper",
          name: "Invitations to Workspace",

          badge: this.countWaitingWorkspaceInvitations || 0,
        },
      ]

    },
    workspaceField() {
      return [
        {
          name: "name",
          label: "Workspace Name",
          placeholder: "My workspace",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
        {
          name: "description",
          label: "Description",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
            rules.max(300, "Your description must not exceed 300 characters"),
          ],
        },

        // {
        //   name: "sep-backend",
        //   label: "Workspace Additional Info",
        //   type: "Separator",
        //   size: "12px",
        //   weight: "600",
        //   width: "180px",
        //   align: "left",
        //   textType: "left",
        //   col: 12,
        //   md: 12,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        // },

        // {
        //   name: "image",
        //   label: "Workspace Image",
        //   type: "File",
        //   col: 12,
        //   md: 12,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   icon: "mdi-upload",
        // },
      ];
    },
    countWaitingWorkspaceInvitations() {

      let total = 0
      for (let invitation of this.workspaceInvitations) {
        if (invitation.status == 'Waiting') {
          total += 1
        }
      }
      return total
    }
  },
  sockets: {
    // connect() {
    // },

    // disconnect() {
    // },
    // // Fired when the server sends something on the "messageChannel" channel.
    // messageChannel(data) {
    // }

    newInvitationToJoinWorkspace() {
      methods.dispatchSuccess(this.$store, "You have received a new invitation to join a new workspace.");

      methods.playNotificationSound('NEW_INVTATION_TO_JOIN_WORKSPACE');

      this.getWorkspaceInvitations({})
    },
    newMemberJoinWorkspace(data) {
      // if(JSON.stringify(this.userProfile._id) != JSON.stringify(data.memberId)){
      methods.dispatchSuccess(this.$store, `${data.memberFullName} has just joined the workspace '${data.workspaceName}' as an ${data.memberRole}`);
      // }

      methods.playNotificationSound('NEW_MEMBER_JOIN_WORKSPACE');

      this.getWorkspaces()
      this.getGuestWorkspaces()

    },
    memberRejectInvitationToJoinWorkspace(data) {
      // if(JSON.stringify(this.userProfile._id) != JSON.stringify(data.memberId)){
      methods.dispatchSuccess(this.$store, `${data.memberFullName} has just declined your invitation to join '${data.workspaceName}'`);
      // }

      methods.playNotificationSound('MEMBER_REJECT_INVITATION_TO_JOIN_WORKSPACE');

      this.getWorkspaces()

    },
    newProjectCreated(data) {
      // if(JSON.stringify(this.userProfile._id) != JSON.stringify(data.memberId)){
      methods.dispatchSuccess(this.$store, `A new project has been added to the workspace '${data.workspaceName}'`);
      // }

      methods.playNotificationSound('NEW_PROJECT_CREATED');

      this.getWorkspaces()
      this.getGuestWorkspaces()

    },
    invitationToJoinWorkspaceCanceled(data) {
      methods.dispatchSuccess(this.$store, `Your invitation to join the workspace ${data.workspaceName} has been cancelled`);
      methods.playNotificationSound('INVITATION_TO_JOIN_WORKSPACE_CANCELED');
      this.getWorkspaceInvitations({})
    },
    memberRemovedFromWorkspace(data) {
      methods.dispatchSuccess(this.$store, `You have been removed from ${data.workspaceName}`);
      methods.playNotificationSound('MEMBER_REMOVED_FROM_WORKSPACE');
      this.getWorkspaceInvitations({})
    },
  },
  methods: {
    ...mapActions([
      "getUserProfile",
      "getProjects",
      "getWorkspaces",
      "getWorkspaceInvitations",
      "getGuestWorkspaces",
      "getMyShopingCart",
      "getActiveSubscription",
    ]),

    async initialize() {
      try {
        this.loading = true;
        await this.getWorkspaces();
        await this.getGuestWorkspaces();

        await this.getWorkspaceInvitations({
          id:this.workspaceInvitationQuery
        });
        await this.getMyShopingCart();
        await this.getActiveSubscription()

        // await this.getProjects();
      } catch (error) {
        console.log("initialize home", error);
        methods.dispatchError(this.$store);
      } finally {
        this.loading = false;
        this.showWorkspace = false;
        this.workspaceModel = {};
        // if (
        //   this.workspaces.length == 0
        // ) {
        //   this.addWorspace()
        // }

        await this.getMyShopingCart();
        await this.getActiveSubscription()

      }
    },
    onSuccess() {
      this.initialize();
      this.$emit("refresh");
    },
    chooseBtn(name) {
      this.activeClass = name;
      if(name != "Invitations to Workspace"){
        this.$router.push("/home")
      }
    },
    addWorspace() {
      this.showWorkspace = !this.showWorkspace;
      this.callBackWorkspace = createWorkspace;
      this.workspaceModel = {};
    },

    async rejectInvitation(invitation) {
      try {
        this.rejectInvitationLoading = true;
        await rejectInvitation({
          id: invitation._id,
          schema: {},
        });

        this.initialize();
        this.$emit("refresh");

        methods.dispatchSuccess(this.$store);
      } catch (error) {
        methods.dispatchError(this.$store);
      }finally{
        this.rejectInvitationLoading = false;
      }
    },
    async joinWorkspace(invitation) {
      try {
        this.joinWorkspaceLoading = true;
        await acceptToJoinWorkspace({
          id: invitation._id,
          schema: {},
        });

        await this.initialize();
        this.$emit("refresh");

        methods.dispatchSuccess(this.$store);

        location.reload()
      } catch (error) {
        methods.dispatchError(this.$store);
      }finally{
        this.joinWorkspaceLoading = false;

      }
    },

    selectWorkspace(schema) {
      this.selectedWorkspace = schema.workspace;

      this.activeClass = "SelectedWorkspace";
      if (schema.action == "show-members") {
        this.showSelectedMembers = true;
        this.showSelectedProjects = false;
        this.showSelectedMarchineServers = false;
      }
      if (schema.action == "show-projects") {
        this.showSelectedMembers = false;
        this.showSelectedProjects = true;
        this.showSelectedMarchineServers = false;
      }
      if (schema.action == "show-marchine-servers") {
        this.showSelectedMembers = false;
        this.showSelectedProjects = false;
        this.showSelectedMarchineServers = true;
      }
    },
    roleClass(role) {
      let result = "";
      if (role == methods.getWorkspaceMemberRoles(true).owner) {
        result += "green--text";
      }
      if (role == methods.getWorkspaceMemberRoles(true).guest) {
        result += "grey--text";
      }
      if (role == methods.getWorkspaceMemberRoles(true).reporter) {
        result += "orange--text";
      }
      if (role == methods.getWorkspaceMemberRoles(true).maintainer) {
        result += "red--text";
      }
      if (role == methods.getWorkspaceMemberRoles(true).developer) {
        result += "blue--text";
      }

      return result;
    },
    statusClass(status) {
      let result = "";
      if (status == methods.getWorkspaceMemberStatus(true).activated) {
        result += "green--text";
      }
      if (status == methods.getWorkspaceMemberStatus(true).disabled) {
        result += "grey--text";
      }
      if (status == methods.getWorkspaceMemberStatus(true).waiting) {
        result += "orange--text";
      }
      if (status == methods.getWorkspaceMemberStatus(true).reject) {
        result += "red--text";
      }
      if (status == methods.getWorkspaceMemberStatus(true).cancel) {
        result += "red--text darken-2";
      }

      return result;
    },
    disabledAddWorkspaceBtn(workspaces) {
      if (this.activesSubscription) {
        return workspaces.length >= this.activesSubscription.maxWorkspace
      }

      return workspaces.length >= 1

    },
    getAddWorkspaceBtnTooltipeMessage(workspaces) {
      if (this.disabledAddWorkspaceBtn(workspaces)) {
        return `You've reached your workspace limit. Upgrade your subscription to create more workspaces or consider deleting existing ones`
      } else {
        const less = (this.activesSubscription ? this.activesSubscription.maxWorkspace : 1) - workspaces.length
        return `Create new workspace . ${less} workspace(s) left before reaching the limit. `
      }



    }
  },
  mounted() {
    this.initialize();
      if(this.workspaceInvitationQuery ){
        this.chooseBtn("Invitations to Workspace")
      }
  },
};
</script>

<style>
#home-sidebar {
  /* height: 100vh; */
  /* max-width: 18vw; */
}

.side-button {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 20px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px,
    rgba(45, 35, 66, 0.15) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  width: 100%;

  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.side-button:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.side-button:hover {
  box-shadow: rgba(45, 35, 66, 0.3) 0 4px 8px,
    rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.side-button:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.side-button.active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

#home-main-bar {
  /* width: 78vw; */
  height: 80vh;
  overflow-y: scroll;
}

@media screen and (max-width: 600px) {
  /* #home-main-bar { */
  /* height: 100%; */
  /* overflow-y: none !important; */
  /* width: 100%; */
  /* overflow-x: none; */
  /* } */
}
</style>