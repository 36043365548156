<template>
    <div>
        <v-form ref="form" class=" " lazy-validation>
            <v-stepper v-if="Array.isArray(steps) && steps.length > 0" v-model="e1">
                <v-stepper-header class="">
                    <template v-for="(step, index) in steps">
                        <v-stepper-step :key="index" :color="color" :complete="e1 > step.step" :step="step.step">
                            {{ step.label }}
                        </v-stepper-step>
                        <v-divider :key="index + 1000" v-if="index < steps.length - 1"></v-divider>
                    </template>


                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content v-for="(step, index) in steps" :key="index" :step="step.step">
                        <div   class="container">
                            <v-form :ref="'step-'+step.step" lazy-validation>

                            <field-x :label="step.label" :hint="step.hint" :multiple="step.multiple"
                                :multipleAddBtnText="step.multipleAddBtnText" :emptyTexte="step.emptyTexte"
                                :fields="step.fields" v-bind:model.sync="model[step.name]" />

                            </v-form>

                        </div>
                        <v-btn :color="color" v-if="!isLastStep()" @click="nextStep" class="white--text">
                            Next
                        </v-btn>
                        <v-btn :loading="loading" :color="color" v-if="isLastStep()" @click="submit()" class="white--text">
                            {{ submitText }}
                        </v-btn>

                        <v-btn class="ml-2" v-if="!isFirstStep()" :disabled="loading" text @click="goBack">
                            Go Back
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-form>
    </div>
</template>
<script>
import FieldX from "./FieldX.vue";
import { methods, rules } from "../../utils/constant.js";

export default {
    components: {
        FieldX,
    },
    props: {
        stepper: {
            type: Object,
            default: () => ({}),
        },
        models: {
            type: Array,
            default: () => [
            ],
        },
        model: {
            default: () => {
                let result = {};
                if (this.multiple == true && !this.model && !this.model._id)
                    result = [];
                return result;
            },
        },
        collection: {
            type: String,
            default: "Cette collection",
        },
        title: {
            type: String,
            default: "Editeur",
        },

        withoutImages: {
            type: Boolean,
            default: true,
        },
        callback: {
            required: true,
        },
        color: {
            type: String,
            default: "primary",
        },
        submitText: {
            type: String,
            default: "Soumettre",
        },
        editorClass: {
            type: String,
            default: "elevation-5",
        },
    },
    data() {
        return {
            e1: 1,
            steps: [],
            rules: rules,
            fields: [],
            loading:false
        }
    },
    watch: {
        stepper() {
            this.initSteps()
        }
    },
    methods: {
        initSteps() {
            if (Array.isArray(this.stepper.steps) && this.stepper.steps.length > 0) {
                if (this.steps.length < 1) {
                    this.steps = []
                    let i = 1
                    for (let item of this.stepper.steps) {
                        this.steps.push({
                            step: i,
                            name: item.name,
                            label: item.label,
                            fields: item.fields,
                        })
                        i++

                        this.fields = this.fields.concat(item.fields)
                    }
                }
            }
        },
        isLastStep() {
            return this.e1 == this.steps[this.steps.length - 1].step
        },
        isFirstStep() {
            return this.e1 == this.steps[0].step
        },
        nextStep() {
            if (!this.isLastStep()) {
                // before next step check field validation 
                if(this.$refs['step-'+this.e1][0].validate()){
                    this.e1 += 1
                }
            }
        },
        goBack() {
            if (!this.isFirstStep()) {
                this.e1 -= 1
            }
        },
        getSchema(model) {
            let schema;



            const keys = Object.keys(this.model)
            model = {}
            for (let key of keys) {
                const instance = Object.assign({}, this.model[key])
                model = {
                    ...model,
                    ...instance
                }

            }

            if (!this.withoutImages) {
                schema = new FormData();

                for (let field of this.fields) {
                    if (field.isFiles) {
                        for (let path of model[field.name]) {
                            schema.append(path, model[field.name]);
                        }
                    } else {
                        if (field.type == "Static") {
                            schema.append(field.name, field.value);
                        } else if (field.type == "Switch") {
                            schema.append(field.name, model[field.name]);
                        } else {
                            if (["Preview", "Separator", "HTMLPreview"].includes(field.type)) {
                                continue;
                            }
                            if (!model[field.name]) continue;

                            if (field.type == "Phone") {
                                schema.append(field.name, model[field.name]);

                                if (field.countryCallingCodeLabel) {
                                    schema.append(
                                        field.countryCallingCodeLabel,
                                        model[field.countryCallingCodeLabel]
                                    );
                                } else {
                                    schema.append(
                                        field.name + "CountryCallingCode",
                                        model[field.name + "CountryCallingCode"]
                                    );
                                }
                                if (field.countryCodeLabel) {
                                    schema.append(
                                        field.countryCodeLabel,
                                        model[field.countryCodeLabel]
                                    );
                                } else {
                                    schema.append(
                                        field.name + "CountryCode",
                                        model[field.name + "CountryCode"]
                                    );
                                }
                                continue;
                            }

                            if (field.type == "Select") {
                                if (
                                    field.select &&
                                    field.select.store &&
                                    field.select.store.getter
                                ) {
                                    let fieldName = field.select.store.fieldName
                                        ? field.select.store.fieldName
                                        : "name";
                                    let fieldValue = field.select.store.fieldValue
                                        ? field.select.store.fieldValue
                                        : "_id";

                                    schema.append(
                                        field.name,
                                        methods.getItemId(
                                            field.select.store.getter,
                                            model[field.name],
                                            fieldName,
                                            fieldValue
                                        )
                                    );
                                    continue;
                                }
                            }
                            if (field.type == "AutoComplete") {
                                if (
                                    field.select &&
                                    field.select.store &&
                                    field.select.store.getter
                                ) {
                                    let fieldName = field.select.store.fieldName
                                        ? field.select.store.fieldName
                                        : "name";
                                    let fieldValue = field.select.store.fieldValue
                                        ? field.select.store.fieldValue
                                        : "_id";

                                    schema.append(
                                        field.name,
                                        methods.getItemId(
                                            field.select.store.getter,
                                            model[field.name],
                                            fieldName,
                                            fieldValue
                                        )
                                    );
                                    continue;
                                }
                            }

                            if (field.type == "Radio") {
                                if (field.radio) {
                                    let fieldName = field.radio.fieldName
                                        ? field.radio.fieldName
                                        : "name";
                                    let fieldValue = field.radio.fieldValue
                                        ? field.radio.fieldValue
                                        : "_id";

                                    schema.append(
                                        field.name,
                                        methods.getItemId(
                                            field.radio.data,
                                            model[field.name],
                                            fieldName,
                                            fieldValue
                                        )
                                    );
                                    continue;
                                }
                            }

                            if (field.type == "Color") {
                                schema.append(
                                    field.name,
                                    model[field.name].hexa || model[field.name]
                                );
                                continue;
                            }

                            schema.append(field.name, model[field.name]);
                        }
                    }
                }
            } else {
                schema = this.getSchemaWithoutFormData(model);
            }

            return schema;
        },
        getSchemaWithoutFormData(model) {
            let schema = {};
            if (!model) {
                model = this.model;
            }
            for (let field of this.fields) {
                if (field.type == "Static") {
                    schema[field.name] = field.value;
                } else if (field.type == "Switch") {
                    schema[field.name] = model[field.name];
                } else {
                    if (["Preview", "Separator", "HTMLPreview"].includes(field.type)) {
                        continue;
                    }
                    if (!model[field.name]) continue;

                    if (field.type == "Select") {
                        if (
                            field.select &&
                            field.select.store &&
                            field.select.store.getter
                        ) {
                            let fieldName = field.select.store.fieldName
                                ? field.select.store.fieldName
                                : "name";
                            let fieldValue = field.select.store.fieldValue
                                ? field.select.store.fieldValue
                                : "_id";

                            schema[field.name] = methods.getItemId(
                                field.select.store.getter,
                                model[field.name],
                                fieldName,
                                fieldValue
                            );
                            continue;
                        }

                        if (
                            field.select &&
                            field.select.objectData &&
                            Array.isArray(field.select.objectData)
                        ) {
                            if (
                                field.select.allowOtherValue == true &&
                                model[field.name] == "Autre"
                            ) {
                                schema[field.name] = model[field.name + "Other"];
                            } else {
                                let fieldName = field.select.fieldName
                                    ? field.select.fieldName
                                    : "name";
                                let fieldValue = field.select.fieldValue
                                    ? field.select.fieldValue
                                    : "value";

                                schema[field.name] = methods.getItemId(
                                    field.select.objectData,
                                    model[field.name],
                                    fieldName,
                                    fieldValue
                                );
                            }

                            continue;
                        }
                    }
                    if (field.type == "AutoComplete") {
                        if (
                            field.select &&
                            field.select.store &&
                            field.select.store.getter
                        ) {
                            let fieldName = field.select.store.fieldName
                                ? field.select.store.fieldName
                                : "name";
                            let fieldValue = field.select.store.fieldValue
                                ? field.select.store.fieldValue
                                : "_id";

                            schema[field.name] = methods.getItemId(
                                field.select.store.getter,
                                model[field.name],
                                fieldName,
                                fieldValue
                            );
                            continue;
                        }

                        if (
                            field.select &&
                            field.select.objectData &&
                            Array.isArray(field.select.objectData)
                        ) {
                            if (
                                field.select.allowOtherValue == true &&
                                model[field.name] == "Autre"
                            ) {
                                schema[field.name] = model[field.name + "Other"];
                            } else {
                                let fieldName = field.select.fieldName
                                    ? field.select.fieldName
                                    : "name";
                                let fieldValue = field.select.fieldValue
                                    ? field.select.fieldValue
                                    : "value";

                                schema[field.name] = methods.getItemId(
                                    field.select.objectData,
                                    model[field.name],
                                    fieldName,
                                    fieldValue
                                );
                            }

                            continue;
                        }
                    }

                    if (field.type == "Radio") {
                        if (field.radio) {
                            let fieldName = field.radio.fieldName
                                ? field.radio.fieldName
                                : "name";
                            let fieldValue = field.radio.fieldValue
                                ? field.radio.fieldValue
                                : "_id";

                            schema[field.name] = methods.getItemId(
                                field.radio.data,
                                model[field.name],
                                fieldName,
                                fieldValue
                            );

                            continue;
                        }
                    }

                    if (field.type == "Color") {
                        schema[field.name] = model[field.name].hexa || model[field.name];
                        continue;
                    }
                    if (field.type == "Phone") {
                        schema[field.name] = model[field.name] = model[field.name];
                        if (field.countryCallingCodeLabel) {
                            schema[field.countryCallingCodeLabel] =
                                model[field.countryCallingCodeLabel];
                        } else {
                            schema[field.name + "CountryCallingCode"] =
                                model[field.name + "CountryCallingCode"];
                        }
                        if (field.countryCodeLabel) {
                            schema[field.countryCodeLabel] = model[field.countryCodeLabel];
                        } else {
                            schema[field.name + "CountryCode"] =
                                model[field.name + "CountryCode"];
                        }

                        continue;
                    }

                    schema[field.name] = model[field.name];
                }
            }

            return schema;
        },

        async submit() {

            // validate customs too 

            let custormIsValidate = true
            if (Array.isArray(this.fields)) {
                let i = 0
                for (const field of this.fields) {
                    if (["SelectX"].includes(field.type)) {
                        if (!this.multiple) {

                            if (Array.isArray(this.$refs[field.name + "Refs" + i]) && this.$refs[field.name + "Refs" + i].length > 0) {
                                if (!this.$refs[field.name + "Refs" + i][0].validate()) {
                                    if (custormIsValidate == true) {
                                        custormIsValidate = false
                                    }
                                }
                            }

                        }
                    }
                    i++
                }
            }
            if (this.$refs.form.validate() && custormIsValidate) {
                try {
                    this.loading = true;

                    let models = [this.model];

                    if (this.multiple == true && !this.id) {
                        models = this.models.map(item => item.model);
                    }
                    let response
                    for (let item of models) {
                        let data = {};
                        data.schema = this.getSchema(item);

                        data.id = this.id || undefined;
                        if (data.schema == undefined) {
                            this.$store.dispatch("setSnackMessage", {
                                message: "Something went wrong",
                                snackbar: {
                                    bg: "red",
                                    color: "white",
                                    status: true,
                                },
                            });
                        }

                        response = await this.callback(data);

                        console.log("response", response);
                    }

                    this.$emit("success", response);
                    this.$store.dispatch("setSnackMessage", {
                        message:
                            this.collection +
                            `has been ${this.id ? "modified" : "added"} successfully`,
                        snackbar: {
                            color: "red",
                            status: true,
                        },
                    });
                } catch (error) {
                    console.log(error);
                    this.$store.dispatch("setSnackMessage", {
                        message: error.response.data.message,
                        snackbar: {
                            bg: "red",
                            color: "white",
                            status: true,
                        },
                    });
                    // console.log(error.response);
                }

                this.loading = false;
            }
        },
    },
    mounted() {
        this.initSteps()
    }

}
</script>