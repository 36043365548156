<template>
  <div class="mb-12">
    <!-- <p class="p-description mt-2 secondary--text">Your projects </p> -->

    <div class="mt-8">
      <div class="d-md-flex justify-space-between">
        <p>
          <span class="workspace-icon primary rounded white--text py-2 px-2 mr-2">{{ workspace.name[0] }}</span>
          <span class="icon-title">{{ workspace.name }} </span>
          <span class="ml-3" v-if="workspace.gitLabLongPath">
            <button class="elevation-0 copy-clipboard-btn btn px-2"
              @click.prevent="copyToClipboard(workspace.gitLabLongPath)"><v-icon size=".9rem">{{
                copiedText == 'Copy' ?
                  'mdi-content-copy' : 'mdi-check' }}</v-icon> {{ copiedText
              }}
              <span class="ml-3" v-if="workspace.gitLabId"><v-icon>mdi-gitlab</v-icon></span>
            </button>
          </span>


          <span class="icon-title ml-3" v-if="!workspace.haveValidSubscription"><v-icon>mdi-lock</v-icon></span>
          <span class="d-block d-md-none mt-2"></span>

          <span v-show="showProjects" class="ml-md-3 ml-10 mt-md-0 rounded green lighten-3 white--text px-2 py-1">{{
            workspace.totalProjects }} project(s) /{{
              workspace.maxProjectPerWorkspace || 2 }}</span>
          <span v-show="showMembers" class="ml-md-3 ml-10 mt-md-0 rounded red lighten-3 white--text px-2 py-1">{{
            workspace.totalMembers }} member(s) /{{
              workspace.maxMemberPerWorkspace || 2 }}</span>
          <span v-show="showSelectedMarchineServers"
            class="ml-md-3 ml-10 mt-md-0 rounded blue lighten-3 white--text px-2 py-1">{{
              workspace.totalMarchineServers }} server(s) </span>
        </p>
        <div class="d-flex">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on"
                v-if="['Owner', methods.getWorkspaceMemberRoles(true).maintainer].includes(workspace.profileMember.role)"
                v-show="showProjects" :disabled="workspace.limitProjectReached" @click="addProject" class="m-btn ml-2 "
                :class="workspace.limitProjectReached ? 'm-btn-disabled' : ''">
                <span><v-icon size="16px" :color="workspace.limitProjectReached ? 'grey' : 'green'"
                    class="mr-1">mdi-plus</v-icon>
                  <span>Add Project</span>

                </span>
              </button>


            </template>
            <span>{{ getAddProjectBtnTooltipeMessage(workspace) }}</span>
          </v-tooltip>
          <button
            v-if="['Owner', methods.getWorkspaceMemberRoles(true).maintainer, methods.getWorkspaceMemberRoles(true).developer].includes(workspace.profileMember.role)"
            v-show="showSelectedMarchineServers" @click="addMarchineServer" class="m-btn ml-2">
            <span><v-icon size="16px" color="green" class="mr-1">mdi-server-plus</v-icon>
              <span> Add Server</span>
            </span>
          </button>
          <button
            v-if="['Owner', methods.getWorkspaceMemberRoles(true).maintainer, methods.getWorkspaceMemberRoles(true).developer].includes(workspace.profileMember.role)"
            v-show="showProjects" @click="toggleMarchineServerScreen" class="m-btn ml-2">
            <span><v-icon size="16px" color="orange" class="mr-1">mdi-server</v-icon>
              Server
            </span>
          </button>

          <v-tooltip bottom>

            <template v-slot:activator="{ on, attrs }">
              <button v-if="['Owner'].includes(workspace.profileMember.role)" v-bind="attrs" v-on="on"
                v-show="showMembers" :disabled="workspace.limitMemberReached" @click="addMember" class="m-btn ml-2"
                :class="workspace.limitMemberReached ? 'm-btn-disabled' : ''">
                <span><v-icon size="16px" :color="workspace.limitMemberReached ? 'grey' : 'green'"
                    class="mr-1">mdi-account-multiple-plus-outline</v-icon>
                  Invite Member
                </span>
              </button>


            </template>
            <span>{{ getInviteMemberBtnTooltipeMessage(workspace) }}</span>
          </v-tooltip>
          <button v-show="showMembers || showSelectedMarchineServers" @click="manageShowProject" class="m-btn ml-2">
            <span><v-icon size="16px" class="mr-1">mdi-code-tags</v-icon>
              Projects
            </span>
          </button>
          <button v-show="showProjects" @click="manageShowMember" class="m-btn ml-2">
            <span><v-icon size="16px" class="mr-1">mdi-account-multiple</v-icon>
              Members
            </span>
          </button>
        </div>
      </div>

      <v-row v-show="showProjects" class="mt-4">
        <v-col v-for="(project, i) of workspace.projects" :key="i" cols="12" md="3">

          <project-card :project="project" :workspace="workspace" />
        </v-col>
        <v-col v-if="!workspace.projects || workspace.projects.length == 0" cols="12">
          <p class="text-center mb-12 h5-description">
            This workspace has no projects yet
          </p>
        </v-col>
      </v-row>
      <v-row v-show="showMembers" class="mt-4">
        <v-col cols="12">
          <v-simple-table dense>

            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Actions</th>
                  <th class="text-left">Account</th>
                  <th class="text-left">Role</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Expiration</th>
                  <th class="text-left">Activity</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="(member, i) of workspace.members" :key="i">
                  <td>
                    <v-menu v-if="['Owner'].includes(workspace.profileMember.role)" elevation="0" right offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" class="mr-10">
                          <v-icon color="primary" size="23">mdi-forwardburger</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-tooltip bottom color="warning">

                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="" icon>
                              <v-icon size="20" @click="editMember(member)" v-bind="attrs" v-on="on"
                                color="warning">mdi-pencil-box-multiple-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Invitation</span>
                        </v-tooltip>
                        <v-tooltip v-if="member.status == 'Waiting'" bottom color="red">

                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="cancelInvitationLoading" class="" icon>
                              <v-icon size="20" @click="cancelInvitation(member)" v-bind="attrs" v-on="on"
                                color="red">mdi-close</v-icon>
                            </v-btn>
                          </template>
                          <span>Cancel Invitation</span>
                        </v-tooltip>
                        <v-tooltip
                          v-if="['Activated', 'Canceled', 'Rejected', 'Reject', 'Cancel'].includes(member.status) && member.role != 'Owner'"
                          bottom color="red">

                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="cancelInvitationLoading" class="" icon>
                              <v-icon size="20" @click="deleteMemberFromWorkspace(member)" v-bind="attrs" v-on="on"
                                color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete From Workspace</span>
                        </v-tooltip>
                      </v-list>
                    </v-menu>
                  </td>
                  <td>
                    <div class="d-flex py-1">
                      <div class="">
                        <v-avatar color="grey lighten-3">
                          <v-img v-if="member.user && member.user.avatar" :src="member.user.avatar"></v-img>
                          <v-icon v-else>mdi-account</v-icon>
                        </v-avatar>
                      </div>

                      <div class="ml-2 d-flex justify-center align-center">
                        <div>
                          <h3 class="h5-title">
                            {{ member.user.lastName }}
                            {{ member.user.firstName }}
                          </h3>
                          <p class="mb-0 h5-description">
                            {{ member.user.username }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span class="px-2 rounded py-2 h5-description" :class="roleClass(member.role)">{{ member.role
                        }}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span class="px-2 rounded py-2 h5-description" :class="statusClass(member.status)">{{
                        member.status
                      }}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span class="px-2 rounded py-2 h5-description"
                        :class="Date.now() > new Date(member.accessExpirationDate) ? 'red--text' : ''">{{
                          member.accessExpirationDate
                        }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="py-1">
                      <ul>
                        <li v-if="member.createdAt" class="h5-description mb-1">
                          <span class="h5-title">Invitation sented : </span>
                          {{ member.createdAt }}
                        </li>
                        <li v-if="member.acceptedAt" class="h5-description mb-1">
                          <span class="h5-title">Access granted :</span>
                          {{ member.acceptedAt }}
                        </li>
                        <li v-if="member.rejectedAt" class="h5-description mb-1">
                          <span class="h5-title">Access rejected :</span>
                          {{ member.rejectedAt }}
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row v-show="showSelectedMarchineServers" class="mt-4">
        <v-col cols="12">
          <v-simple-table dense>

            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Actions</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Host</th>
                  <th class="text-left">Port</th>
                  <th class="text-left">Os</th>
                  <th class="text-left">Connected</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="(marchine, i) of workspace.marchineServers" :key="i">
                  <td>
                    <v-menu elevation="0" right offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" class="mr-10">
                          <v-icon color="primary" size="23">mdi-forwardburger</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-tooltip bottom color="warning">

                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="" icon>
                              <v-icon size="20" @click="editMarchineServer(marchine)" v-bind="attrs" v-on="on"
                                color="warning">mdi-pencil-box-multiple-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Server</span>
                        </v-tooltip>
                        <v-tooltip bottom color="red">

                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="" icon>
                              <v-icon size="20" @click="deleteMarchineServer(marchine)" v-bind="attrs" v-on="on"
                                color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Server</span>
                        </v-tooltip>
                        <v-tooltip bottom color="black">

                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="" icon>
                              <v-icon size="20" @click="verifyConnection(marchine)" v-bind="attrs" v-on="on"
                                color="black">mdi-connection</v-icon>
                            </v-btn>
                          </template>
                          <span>Verify Connection To Server</span>
                        </v-tooltip>

                      </v-list>
                    </v-menu>
                  </td>
                  <td>
                    <div class="d-flex py-1">
                      <div class="">
                        <h3 class="h5-title">
                          {{ marchine.name }}
                        </h3>
                      </div>


                    </div>
                  </td>
                  <td>
                    <div>
                      <h3 class="h5-title">
                        {{ marchine.host }}
                      </h3>

                    </div>
                  </td>
                  <td>
                    <div>

                      <h3 class="h5-title">
                        {{ marchine.port }}
                      </h3>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h3 class="h5-title">
                        {{ marchine.os }}
                      </h3>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h3 class="h5-title" :class="marchine.connectionEstablished ? 'green--text' : 'red--text'">
                        {{ marchine.connectionEstablished ? 'Established' : ' Failed' }}
                      </h3>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>

    <!--  -->
    <v-dialog v-model="showAddProject" max-width="70rem">
      <stepper-x @success="onSuccess" :stepper="projectField" :callback="callBackProject" :model="projectModel"
        title="Project" :withoutImages="false" :formType="'Stepper'" v-if="showAddProject"
        submitText="Create Project" color="primary" />
    </v-dialog>

    <v-dialog v-model="showAddMarchineServer" max-width="50rem">
      <editor-x @success="onSuccess" :fields="marchineServerField" :callback="callBackMarchineServer"
        :model="marchineServerModel" title="Marchine Server" :withoutImages="true" v-if="showAddMarchineServer"
        :submitText="marchineServerModel && marchineServerModel._id ? `Update Marchine` : `Create Marchine`" />
    </v-dialog>

    <v-dialog v-model="showMemberDialog" max-width="50rem">
      <editor-x @success="onSuccess" :fields="memberField" :callback="callBackMember" :model="memberModel"
        title="Member" v-if="showMemberDialog" :submitText="memberModel && memberModel._id ? 'Update member' : 'Invite member'
          " />
    </v-dialog>
    <v-dialog v-model="showConnectionInterface" max-width="50rem">
      <marchine-server-connection v-if="showConnectionInterface" :marchine="marchineServerModel"
        :callback="callBackMarchineServer" @closeDialog="closeMarchineServerDialog" @success="onSuccess" />
    </v-dialog>
  </div>
</template>

<script>
import EditorX from "../universal/EditorX.vue";
import StepperX from "../universal/StepperX.vue";
import ProjectCard from "./ProjectCard.vue";
import { methods, rules } from "@/utils/constant.js";
import {
  createProject,
  inviteMember,
  updateMemberRole,
  cancelInvitation,
  deleteMemberFromWorkspace,
  createMarchineServer,
  updateMarchineServer,
  deleteMarchineServer,
  verifyMarchineServerConnection
} from "@/api/user.js";
import { mapGetters, mapActions } from "vuex";
import MarchineServerConnection from './MarchineServerConnection.vue';

export default {
  components: { ProjectCard, EditorX, StepperX, MarchineServerConnection },
  props: {
    workspace: {
      type: Object,
      required: true,
    },
    showMembers: {
      type: Boolean,
      default: false,
    },
    showProjects: {
      type: Boolean,
      default: true,
    },
    showSelectedMarchineServers: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["users"]),

    projectField() {
      return {
        name: "products",
        label: "Transaction products",
        emptyTexte: "Add the product models you wish to sell.",
        multipleAddBtnText: "Add product model",
        type: "FieldX",
        isRequiredForUpdate: false,
        isRequiredForAdd: false,
        existForUpdate: false,
        existForAdd: true,
        steps: [
          {
            label: "Generale Information",
            name: "step-1",
            type: "FieldX",
            multiple: false,
            isRequiredForUpdate: false,
            isRequiredForAdd: false,
            existForUpdate: false,
            existForAdd: true,
            fields: [
              {
                name: "workspace",
                label: "",
                type: "Static",
                col: 12,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.workspace._id,
              },

              {
                name: "sep-general",
                label: "Name and description are required",
                type: "Separator",
                size: "12px",
                weight: "600",
                width: "250px",
                align: "left",
                textType: "left",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
            
              {
                name: "name",
                label: "Project Name",
                placeholder: "App",
                type: "String",
                col: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-card-bulleted-settings-outline",
              },
              {
                name: "description",
                label: "Description",
                type: "String",
                col: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                rules: [
                  rules.max(300, "Your description must not exceed 300 characters"),
                ],
              },
              {
                name: "image",
                label: "Project Image",
                type: "File",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-upload",
              },

            ]
          },
          {
            name:"step-2",
            label: "Backend Config",
            type: "FieldX",
            multiple: false,
            isRequiredForUpdate: false,
            isRequiredForAdd: false,
            existForUpdate: false,
            existForAdd: true,
            fields: [
              // {
              //   name: "template",
              //   label: "Template",
              //   hint: "You can create from template",
              //   type: "Select",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: false,
              //   existForAdd: true,
              //   select: {
              //     store: {
              //       action: this.getTemplates,
              //       params: {
              //         withAuthMiddleware: true,
              //         $axios: this.$axios,
              //         project: this.$route.params.id,
              //       },
              //       getter: this.templates,
              //       fieldName: "name",
              //     },
              //   },
              // },

              {
                name: "sep-backend",
                label: "Backend type is required",
                type: "Separator",
                size: "12px",
                weight: "600",
                width: "180px",
                align: "left",
                textType: "left",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              {
                name: "backendType",
                label: "Backend Type",
                type: "Select",
                col: 12,
                md: 6,

                isRequiredForUpdate: false,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                select: {
                  data: methods.getBackendType(false),
                },
                icon: "mdi-nodejs",
              },

              {
                name: "port",
                label: "Backend Server Port",
                type: "Number",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-router-network",
              },
              {
                name: "packageManager",
                label: "Package Manager",
                type: "SelectX",
                select: {

                  data: [
                    {
                      name: methods.getPackageManager(true).npm,
                      icon: "mdi-npm",
                      badge: "free",
                      color: "red"
                      // authorizePlans: ["free", "standard", "advanced"]

                    },
                    {
                      name: methods.getPackageManager(true).yarn,
                      icon: "mdi-package",
                      badge: "free",
                      color: "red"
                      // authorizePlans: ["free", "standard", "advanced"]

                    },
                  ],

                },
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-router-network",
              },
            ]
          },
          {
            name:"step-3",
            label: "Database Config",
            type: "FieldX",
            multiple: false,
            isRequiredForUpdate: false,
            isRequiredForAdd: false,
            existForUpdate: false,
            existForAdd: true,
            fields: [
              {
                name: "sep-backend",
                label: "Database type is required",
                type: "Separator",
                size: "12px",
                weight: "600",
                width: "180px",
                align: "left",
                textType: "left",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              {
                name: "databaseType",
                label: "Database Type",
                type: "Select",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                select: {
                  data: methods.getDatabaseType(false),
                },
                icon: "mdi-database",
              },
              {
                name: "database",
                label: "Database Name",
                type: "String",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-database-cog",
              },
              {
                name: "databaseHost",
                hint: "The hostname or IP address where your database is running",
                label: "Database Host",
                type: "String",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-database-cog",
                default: "localhost"

              },
              {
                name: "databasePort",
                hint: "The port number on which your database is listening for connections.",
                label: "Database Port",
                type: "String",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-database-cog",
                default: "27017"
              },


              {
                name: "databaseUsername",
                label: "Database Username",
                hint: " If authentication is enabled, the username used to connect to the database",
                type: "String",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-database-cog",
              },
              {
                name: "databasePassword",
                label: "Database Password",
                hint: "The password associated with the username for authentication",
                type: "String",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-database-cog",
              },
            ]
          },
          {
            name:"step-4",
            label: "SMTP config",
            type: "FieldX",
            multiple: false,
            isRequiredForUpdate: false,
            isRequiredForAdd: false,
            existForUpdate: false,
            existForAdd: true,
            fields: [
              {
                name: "smtp-config-sep",
                label: "If you don't have this information you can skip this step",
                type: "Separator",
                size: "12px",
                weight: "600",
                width: "600px",
                align: "left",
                textType: "left",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              {
                name: "mailHost",
                label: "SMTP Host",
                type: "String",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-gmail",
              },
              {
                name: "mailPort",
                label: "SMTP Port",
                type: "Number",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-mailbox-outline",
              },
              {
                name: "mailAddresse",
                label: "SMTP Addresse",
                type: "String",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-email-outline",
              },
              {
                name: "mailPassword",
                label: "SMTP Password",
                type: "String",
                col: 12,
                md: 6,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                icon: "mdi-email-lock",
              },]
          },

        ]
      };
    },
    memberField() {
      const memberRoles = [
        methods.getWorkspaceMemberRoles(true).maintainer,
        methods.getWorkspaceMemberRoles(true).developer,
        methods.getWorkspaceMemberRoles(true).reporter,
        methods.getWorkspaceMemberRoles(true).guest,
      ]
      return [
        {
          name: "workspace",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.workspace._id,
        },
        {
          name: "user",
          label: "User",
          type: "AutoComplete",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          icon: "mdi-account-circle",
          select: {
            store: {
              action: this.getUsers,
              params: {
                // $axios: this.$axios,
                // project: this.$route.params.id,
                // isActor:true
                isActive: true,
                withoutMyAccount: true,
                workspace: this.workspace._id
              },
              getter: this.users,
              fieldName: "username",
            },
          },
        },
        {
          name: "role",
          label: "Role",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: memberRoles,
          },
        },
        {
          name: "accessExpirationDate",
          label: "Access expiration date",
          type: "Date",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
    marchineServerField() {
      return [
        {
          name: "workspace",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.workspace._id,
        },

        {
          name: "os",
          label: "Operating system",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getOperatingSystem(false),
          },
        },
        {
          name: "name",
          label: "Server Name",
          placeholder: "Name",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
        {
          name: "host",
          label: "Server Host",
          placeholder: "127.0.0.1",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-ip",
        },
        {
          name: "port",
          label: "Server Port",
          type: "Number",
          placeholder: "22",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-router-network",
        },
        {
          name: "username",
          label: "Server Username",
          placeholder: "root",
          hint: "This user must have sudo privileges",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-account",
        },
        {
          name: "password",
          label: "Server Password",
          placeholder: "",
          type: "Password",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-lock",
        },
      ];
    },
  },
  data() {
    return {
      showAddProject: false,

      callBackMarchineServer: null,
      callBackProject: null,
      callBackMember: null,
      projectModel: {},
      memberModel: {},
      marchineServerModel: {},
      showMemberDialog: false,
      showAddMarchineServer: false,

      cancelInvitationLoading: false,
      deleteMemberFromWorkspaceLoading: false,
      showSelectedMembers: false,
      showSelectedProjects: false,
      showConnectionInterface: false,
      methods: methods,
      copiedText: "Copy"
    };
  },
  methods: {
    ...mapActions(["getUsers"]),


    addProject() {
      if (this.workspace.limitProjectReached) {
        alert("Add project limit reached")
        return
      }
      this.showAddProject = !this.showAddProject;
      this.callBackProject = createProject;
      this.projectModel = {};
    },
    addMarchineServer() {
      this.showAddMarchineServer = !this.showAddMarchineServer;
      this.callBackMarchineServer = createMarchineServer;
      this.marchineServerModel = {};
    },

    editMarchineServer(marchine) {
      this.showAddMarchineServer = !this.showAddMarchineServer;
      this.callBackMarchineServer = updateMarchineServer;
      this.marchineServerModel = Object.assign({}, marchine);
    },
    verifyConnection(marchine) {
      this.showConnectionInterface = !this.showConnectionInterface;
      this.callBackMarchineServer = verifyMarchineServerConnection;
      this.marchineServerModel = Object.assign({}, marchine);
    },
    closeMarchineServerDialog() {
      this.showConnectionInterface = !this.showConnectionInterface;
      this.marchineServerModel = {};
    },

    deleteMarchineServer(marchine) {
      methods.dispatchDeleteDialog(this.$store, {
        dialog: true,
        what: "Do you really want to delete this server",
        callback: deleteMarchineServer,
        data: { id: marchine._id },
        successHandler: () => {
          this.onSuccess();
        },
        description: "Every app create in this server will be remove ",
      });
    },

    async toggleMarchineServerScreen() {
      try {
        this.showSelectedMarchineServers = !this.showSelectedMarchineServers;

        if (this.showSelectedMarchineServers == false) {
          this.showProjects = true;
          this.showMembers = false;
        } else {
          this.showMembers = false;
          this.showProjects = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    addMember() {
      if (this.workspace.limitMemberReached) {
        alert("Member limit reached")
        return
      }
      this.showMemberDialog = !this.showMemberDialog;
      this.callBackMember = inviteMember;
      this.memberModel = {};
    },
    editMember(member) {
      this.showMemberDialog = !this.showMemberDialog;
      this.callBackMember = updateMemberRole;
      this.memberModel = member;
    },
    async cancelInvitation(member) {
      try {
        this.cancelInvitationLoading = true;
        await cancelInvitation({
          id: member._id,
          schema: {},
        });

        this.initialize();
        this.$emit("refresh");

        methods.dispatchSuccess(this.$store);
      } catch (error) {
        methods.dispatchError(this.$store);
      } finally {
        this.cancelInvitationLoading = false;

      }
    },
    async deleteMemberFromWorkspace(member) {
      try {
        this.cancelInvitationLoading = true;
        await deleteMemberFromWorkspace({
          id: member._id,
          schema: {},
        });

        this.initialize();
        this.$emit("refresh");

        methods.dispatchSuccess(this.$store);
      } catch (error) {
        methods.dispatchError(this.$store);
      }
      finally {
        this.cancelInvitationLoading = false;

      }
    },
    onSuccess() {
      this.initialize();
      this.$emit("refresh");
    },
    initialize() {
      this.showConnectionInterface = false

      this.marchineServerModel = {};
      this.projectModel = {};
      this.callBackProject = null;
      this.callBackMember = null;
      this.showAddProject = false;
      this.showMemberDialog = false;
      this.showAddMarchineServer = false;
      this.cancelInvitationLoading = false;
    },
    manageShowMember() {
      this.showMembers = !this.showMembers;
      if (this.showMembers == false) {
        this.showProjects = true;
        this.showSelectedMarchineServers = false;
      } else {
        this.showProjects = false;
        this.showSelectedMarchineServers = false;
      }
    },
    manageShowProject() {
      this.showProjects = !this.showProjects;
      if (this.showProjects == false) {
        this.showMembers = true;
        this.showSelectedMarchineServers = false;
      } else {
        this.showMembers = false;
        this.showSelectedMarchineServers = false;
      }
    },
    roleClass(role) {
      let result = "";
      if (role == methods.getWorkspaceMemberRoles(true).owner) {
        result += "green--text";
      }
      if (role == methods.getWorkspaceMemberRoles(true).guest) {
        result += "grey--text";
      }
      if (role == methods.getWorkspaceMemberRoles(true).reporter) {
        result += "orange--text";
      }
      if (role == methods.getWorkspaceMemberRoles(true).maintainer) {
        result += "red--text";
      }
      if (role == methods.getWorkspaceMemberRoles(true).developer) {
        result += "blue--text";
      }

      return result;
    },
    statusClass(status) {
      let result = "";
      if (status == methods.getWorkspaceMemberStatus(true).activated) {
        result += "green--text";
      }
      if (status == methods.getWorkspaceMemberStatus(true).disabled) {
        result += "grey--text";
      }
      if (status == methods.getWorkspaceMemberStatus(true).waiting) {
        result += "orange--text";
      }
      if (status == methods.getWorkspaceMemberStatus(true).reject) {
        result += "red--text";
      }
      if (status == methods.getWorkspaceMemberStatus(true).cancel) {
        result += "red--text darken-2";
      }
      if (status == methods.getWorkspaceMemberStatus(true).expired) {
        result += "red--text darken-3";
      }

      return result;
    },
    getAddProjectBtnTooltipeMessage(workspace) {

      if (workspace.limitProjectReached) {
        return `You've reached your project limit per workspace. Upgrade your subscription to create more projects or consider deleting existing ones`
      } else {
        const less = (workspace.maxProjectPerWorkspace || 2) - workspace.totalProjects
        return `Create new project . ${less} project(s) left before reaching the limit. `
      }
    },
    getInviteMemberBtnTooltipeMessage(workspace) {

      if (workspace.limitMemberReached) {
        return `You've reached your member limit per workspace. Upgrade your subscription to create more members or consider deleting existing ones`
      } else {
        const less = (workspace.maxMemberPerWorkspace || 2) - workspace.totalMembers
        return `Invite new member . ${less} member(s) left before reaching the limit. `
      }
    },
    copyToClipboard(data) {
      if (!navigator.clipboard) {
        console.error('Clipboard API not supported');
        return;
      }
      navigator.clipboard.writeText(data).then(() => {
        console.log('Data copied to clipboard successfully!');
        this.copiedText = "Copied"
        setTimeout(() => {
          this.copiedText = "Copy"
        }, 2000)
      }).catch(err => {
        console.error('Failed to copy data to clipboard: ', err);
      });
    }
  },
};
</script>

<style></style>