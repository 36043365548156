<template>
  <div>

    <article class="project_card" @click="goTo('/project/'+project._id)">
      <div class="temporary_text">
        <img :src="project.image  " :alt="project.name" />

      </div>
      <div class="project_card_content">
        <span class="project_card_title">{{ project.name }}
          <!-- <span class="plan-badge ml-3" v-if="!project.projectHaveValidSubscription"
            >{{ project.plan || 'free' }}</span
          > -->

          <span class="ml-3" v-if="workspace.haveValidSubscription && !project.haveValidSubscription"><v-icon size="16px">mdi-lock </v-icon> </span>

        </span>
        <span class="project_card_subtitle mt-1"
          > 
          <span class="mr-3"><v-icon size="16px">mdi-table </v-icon> <span class="ml-1 sub-number secondary-text "> {{ project.totalModels }}</span></span>
          <span class="mr-3"><v-icon size="16px">mdi-account </v-icon> <span class="ml-1 sub-number secondary-text"> {{ project.totalActors }}</span></span>
          <!-- <span class="mr-3"><v-icon size="16px">mdi-flare </v-icon> <span class="ml-1 sub-number secondary-text"> 3</span></span> -->
          
          </span
        >
        <p class="project_card_description">
          <span class="font-weight-bold d-block mb-3 mt-2 red--text" v-if="workspace.haveValidSubscription && !project.haveValidSubscription"> NB : This project is currently locked due to an invalid subscription.</span>
          
          {{ project.description }}
          
         
        </p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  props:{
    project:{
      type:Object,
      required:true
    },
    workspace:{
      type:Object,
      required:true
    }
  },
    methods:{
        goTo(link) {
          if(this.workspace.haveValidSubscription && !this.project.haveValidSubscription){
            alert("This project is currently locked due to an invalid subscription. Please reload the page in case it is a propagation error.")

          }else{
            this.$router.push(link);
          }
    },
    }
};
</script>

<style>
.project_card {
  position: relative;
  width: 100%;
  height: 250px;
  color: #2e2d31;
  background: #131313;
  overflow: hidden;
  border-radius: 20px;
  cursor:pointer;
}
.project_card img{
    width: 100% !important;
}

.temporary_text {
  font-weight: bold;
  font-size: 24px;
  /* padding: 6px 12px; */
  color: #f8f8f8;
}

.plan-badge {
  font-weight: 400;
  color: orange;

}
.project_card_title {
  font-weight: bold;
}

.project_card_content {
  position: absolute;
  left: 0;
  bottom: 0;
  /* edit the width to fit project_card */
  width: 100%;
  padding: 20px;
  background: #f2f2f2;
  border-top-left-radius: 20px;
  /* edit here to change the height of the content box */
  transform: translateY(150px);
  transition: transform 0.25s;
}

.project_card_content::before {
  content: "";
  position: absolute;
  top: -47px;
  right: -45px;
  width: 100px;
  height: 100px;
  transform: rotate(-175deg);
  border-radius: 50%;
  box-shadow: inset 48px 48px #f2f2f2;
}

.project_card_title {
  color: #131313;
  line-height: 15px;
}

.project_card_subtitle {
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}

.project_card_description {
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.5s;
  min-height: 9rem;
}

.project_card:hover .project_card_content {
  transform: translateY(0);
}

.project_card:hover .project_card_description {
  opacity: 1;
  transition-delay: 0.25s;
}

.sub-number {
    font-size: 11px;
}


</style>